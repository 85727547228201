import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageTitle from "../Components/PageTitle";
import ApiService, { CastSummaryModel } from "../Services/ApiService";

const ColouredNameSummaryCharacterDisplay = ({character} : { character: CastSummaryModel}) => {
    return (<Card border="primary" className="h-100 border-thick">
        <Link to={character.url}>
            <picture>
                <source srcSet={character.iconUrl} media="(min-width: 576px)" />
                <Card.Img className="no-border-radius" 
                            variant="top" 
                            src={character.iconMobileUrl ?? character.iconUrl} /> 
            </picture>
        </Link>
        <Link to={character.url}>
            <h3 className="text-center bg-blue p-1 text-white hover-bg-white m-0">
                {character.name}
            </h3>
        </Link>
        <Row className="pl-2 pr-2 m-0 h-100 align-items-center bg-off-white">
            <Col className="justify-content-center">
                <h4 className="text-center">{character.summary}</h4>
            </Col>
        </Row>
    </Card>);
};

const CastIndexPage = () => {

    const [cast, setCast] = useState<CastSummaryModel[]>([]);

    useEffect(() => {
        const callback = async () => {
            const theCast = await ApiService.getService().getCastIndex();
            theCast.sort((a,b) => a.position - b.position);
            setCast(theCast);
        }
        
        callback(); 
    }, []);

    document.title = `Meet the crew | Ramphi and friends`;

    return (<Container>
        <Row className="mt-3 mb-1">
            <Col>
                <PageTitle className="text-center">Meet the crew</PageTitle>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                <h4 className="text-center">Find everything and more you need to know about the characters we have met here!</h4>
            </Col>
        </Row>
        <Row className="justify-content-center">
            {cast.map((c,i) => <Col md={3} sm={6} xs={12} key={`cast-${i}`} className="mb-2 pl-3 pr-3">
                <ColouredNameSummaryCharacterDisplay character={c} />
            </Col>)}
        </Row>
    </Container>)
}

export default CastIndexPage;