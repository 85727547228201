import config from "../config";

export interface IApiService {
    getComicContents() : Promise<ComicContents>;
    getComicEpisode(title: string) : Promise<ComicEpisode>;

    getCastIndex(): Promise<CastSummaryModel[]>;
    getCastMember(characterId: string): Promise<CastDetailModel>;

    addLike(title: string) : Promise<void>;
    removeLike(title: string) : Promise<void>;
    getLikes(title: string) : Promise<number>;
}

export interface ComicContents {
    title: string;
    description: string;
    episodes: EpisodeSummary[];
    preview: EpisodeSummary | null;
}

export interface EpisodeSummary {
    episode: number;
    url: string;
    title: string;
    coverUrl: string;
    published: any;
    publishTime: Date;
}

export interface ComicEpisode {
    episode: number;
    url: string;
    title: string;
    coverUrl: string;
    panelRoot: string;
    panelCount: number;
    previousUrl: string|null;
    nextUrl: string|null;
    publishDate: Date|null;
}

export interface CastSummaryModel {
    name: string;
    url: string;
    summary: string;
    iconUrl: string;
    iconMobileUrl?: string;
    position: number;
};

export interface CastDetailModel {
    name: string;
    url: string;
    summary: string;
    description: string;
    iconUrl: string;
    iconMobileUrl?: string;
    portfolio: string[];
};

export default class ApiService implements IApiService {

    async addLike(title: string): Promise<void> {

        const data = {
            title: title,
            action: "add"
        };

        const body = JSON.stringify(data);

        await fetch(config.apiHost + "/likes", {
            method: "POST",
            body: body,
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    async removeLike(title: string): Promise<void> {
        const data = {
            title: title,
            action: "remove"
        };

        const body = JSON.stringify(data);

        await fetch(config.apiHost + "/likes", {
            method: "POST",
            body: body,
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    async getLikes(title: string): Promise<number> {
        var response = await fetch(config.apiHost + "/likes?episode=" + title, {
            method: "GET"
        });

        if (!response.ok) {
            return 0;
        }

        const contents :any = (await response.json());

        try {
            return contents[title];
        } catch (e) {
            return 0;
        }
    }
    async getComicContents(): Promise<ComicContents> {
        var response = await fetch(config.apiHost + "/comic", {
            method: "GET"
        });

        if (!response.ok) {
            // retry once
            response = await fetch(config.apiHost + "/comic", {
                method: "GET"
            });
            if (!response.ok) {
                // still not ok, so fail
                throw new Error('Failed to contact API to get comic details');
            }
        }

        const contents = (await response.json()) as ComicContents;

        // fix the published times
        contents.episodes = contents.episodes.map(e => {
            e.publishTime = new Date(e.published);
            return e;
        });

        if (contents.preview !== null) {
            contents.preview.publishTime = new Date(contents.preview.published);
        }

        return contents;
    }

    async getComicEpisode(title: string): Promise<ComicEpisode> {
        var response = await fetch(config.apiHost + "/comic/" + title, {
            method: "GET"
        });

        if (!response.ok) {
            // retry once
            response = await fetch(config.apiHost + "/comic/" + title, {
                method: "GET"
            });
            if (!response.ok) {
                // still not ok, so fail
                throw new Error('Failed to contact API to get episode details');
            }
        }

        const contents = (await response.json()) as ComicEpisode;

        
        if (contents.publishDate !== null) {
            contents.publishDate = new Date(contents.publishDate);
        }

        return contents;
    }

    async getCastIndex(): Promise<CastSummaryModel[]> {
        var response = await fetch(config.apiHost + "/crew", {
            method: "GET"
        });

        if (!response.ok) {
            // retry once
            response = await fetch(config.apiHost + "/crew", {
                method: "GET"
            });
            if (!response.ok) {
                // still not ok, so fail
                throw new Error('Failed to contact API to get comic details');
            }
        }

        const cast = (await response.json()) as CastSummaryModel[];

        return Promise.resolve(cast);
    }

    async getCastMember(characterId: string): Promise<CastDetailModel> {
        var response = await fetch(config.apiHost + "/crew/" + characterId, {
            method: "GET"
        });

        if (!response.ok) {
            // retry once
            response = await fetch(config.apiHost + "/crew/" + characterId, {
                method: "GET"
            });
            if (!response.ok) {
                // still not ok, so fail
                throw new Error('Failed to contact API to get episode details');
            }
        }

        const contents = await response.json() as Promise<CastDetailModel>;

        function sleep(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        await sleep(2000);

        return contents;
    }

    static instance : IApiService | null= null;
    static getService() : IApiService {
        if (this.instance === null) {
            this.instance = new ApiService();
        }
        return this.instance;
    }
}