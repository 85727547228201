import { faDeviantart, faInstagram, faTiktok, faTumblr, faTwitch, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faCoffee, faSoap, IconDefinition } from "@fortawesome/free-solid-svg-icons";

export interface ISocialMedia {
    service: string;
    icon: IconDefinition;
    href: string;
}

export default class SocialsService {
    getSocials() : ISocialMedia[] {
        return [
            {
                service: "Instagram",
                icon: faInstagram,
                href: "https://www.instagram.com/orangeramphastos/"
            },
            {
                service: "Twitter",
                icon: faTwitter,
                href: "https://twitter.com/OrangeRamphasto"
            },
            {
                service: "Twitch",
                icon: faTwitch,
                href: "https://www.twitch.tv/orangedrawer"
            },
            {
                service: "YouTube",
                icon: faYoutube,
                href: "https://www.youtube.com/channel/UCc1-r7_BQAYz8svlAkz4rcw"
            },
            {
                service: "DeviantArt",
                icon: faDeviantart,
                href: "https://www.deviantart.com/orangeramphastos"
            },
            {
                service: "tumblr",
                icon: faTumblr,
                href: "https://www.tumblr.com/blog/orangeramphastosart"
            },
            {
                service: "RedBubble",
                icon: faSoap,
                href: "https://www.redbubble.com/people/OrangeRamphasto/shop"
            },
            {
                service: "ko-fi",
                icon: faCoffee,
                href: "https://ko-fi.com/orangeramphastos"
            },
            {
                service: "tiktok",
                icon: faTiktok,
                href: "https://www.tiktok.com/@orangeramphastos"
            }
        ];
    }
}
