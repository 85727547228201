import { faChevronCircleLeft, faChevronCircleRight, faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ApiService, { ComicEpisode } from "../Services/ApiService";
import LikeService from "../Services/LikeService";
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from "react-share";

interface Panel {
    altText: string;
    url: string;
    mobile: string;
}

interface ComicNavigationButtonsProps {
    previousUrl: string|null;
    nextUrl: string|null;
}

const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        dateStyle: "medium"
    };
    const formatter = new Intl.DateTimeFormat(navigator.language, options);
    return formatter.format(date);
}

const ComicNavigationButtons = ({previousUrl, nextUrl}: ComicNavigationButtonsProps) => {

    return (
        <Navbar bg="secondary" variant="light" className="lh-1 p-0">
            <Nav justify className="w-100">
                <Nav.Item>
                   {previousUrl !== null && 
                    <Nav.Link as={Link} to={previousUrl ?? ""}><FontAwesomeIcon icon={faChevronCircleLeft}/> Previous</Nav.Link>
                   }
                </Nav.Item>
                <Nav.Item />
                <Nav.Item>
                    {nextUrl !== null &&
                        <Nav.Link as={Link} to={nextUrl ?? ""}>Next <FontAwesomeIcon icon={faChevronCircleRight}/></Nav.Link>
                    }
                </Nav.Item>
            </Nav>
        </Navbar>
    );
};

const LikeTextDisplay = ({liked, count} : {liked: boolean, count: number}) => {
    if (liked) {
        if (count > 1) {
            return <span>You and {count-1 === 1 ? "one other person" : `${count-1} other people`} like this episode!</span>
        }
        return <span>You like this episode!</span>
    }
    if (count === 0) {
        return <span>Be the first to like this episode!</span>
    }
    if (count === 1) {
        return <span>One other person likes this episode!</span>
    }
    return <span>{count} other people like this episode!</span>
}

const ComicDetailsPage = () => {

    const desktopColumnCount = 2;

    const {title: theTitle} = useParams<{title: string}>();
    const title = theTitle ?? "";

    const [episode, setEpisode] = useState<ComicEpisode|null>(null);
    const [panels, setPanels] = useState<Panel[][]>([]);

    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);

    useEffect(() => {
        const getEpisode = async() => {
            const ep = await ApiService.getService().getComicEpisode(title);
            setEpisode(ep);
        };

        const getUserLike = async() => {
            const likes = await LikeService.getService().getUserLike(title);
            setLiked(likes);
        }

        const getLikeCount = async() => {
            const likes = await LikeService.getService().getLikeCount(title);
            setLikeCount(Math.max(0, likes));
        }

        getEpisode();
        getUserLike();
        getLikeCount();
    },[title]);

    useEffect(() => {

        if (episode === null) {
            return; // do nothing with a null episode
        }

        const rowCount = episode.panelCount / desktopColumnCount;

        const panelBuilder: Panel[][] = [];
        for (let r = 0; r < rowCount; r++) {
            const row : Panel[] = [];

            for (let c = 0; c < desktopColumnCount; c++) {
                const linearIndex = r*desktopColumnCount + c + 1;
                const panel = {
                    altText: `Panel ${linearIndex}`,
                    url: `${episode.panelRoot}/panel-${linearIndex}.png`,
                    mobile: `${episode.panelRoot}/panel-${linearIndex}-mobile.png`
                };
                row.push(panel);
            }

            panelBuilder.push(row);
        }

        
        document.title = `${episode.title} | Ramphi and friends`;
        setPanels(panelBuilder);

    }, [episode]);

    const toggleLike = async () => {
        const newLiked = !liked;
        setLiked(newLiked);

        if (newLiked) {
            await LikeService.getService().addLike(title);
        } else {
            await LikeService.getService().removeLike(title);
        }

        const likes = await LikeService.getService().getLikeCount(title);
        setLikeCount(Math.max(0, likes));
    }
 
    return (
    <Container fluid className="m-0 p-0"> { episode !== null && <>
        <Row className="m-0 p-0">
            <Col className="m-0 p-0">
                <ComicNavigationButtons previousUrl={episode?.previousUrl} nextUrl={episode?.nextUrl} />
            </Col>
        </Row>
        <Row className="w-90 mx-auto"><Col>
            <Row>
                <Col>
                    <h1 className="text-primary">{episode?.title}</h1>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col>
                    <p className="m-1">
                        <FontAwesomeIcon icon={liked ? faHeart : farHeart} className="text-orange mr-1" onClick={toggleLike}/>
                        <LikeTextDisplay count={likeCount} liked={liked}/>
                    </p>
                </Col>
            </Row>
            <Row><Col>
                {panels.map((row,i) => <Row key={`panel-row-${i}`} className="no-gutters">
                    {row.map((p, j) => <Col key={`panel-${i}-${j}`} xs={12} sm={6} className="p-1">
                        <picture className="img-fluid">
                            <source srcSet={p.url} media="(min-width: 576px)" />
                            <img src={p.mobile} 
                                    alt={p.altText} 
                                    className="img-fluid"
                                    /> 
                        </picture>
                    </Col>)}
                </Row>)}
            </Col></Row>
            <Row className="align-items-center">
                <Col>
                    <p className="m-1">
                        <FontAwesomeIcon icon={liked ? faHeart : farHeart} className="text-orange mr-1" onClick={toggleLike}/>
                        <LikeTextDisplay count={likeCount} liked={liked}/>
                    </p>
                </Col>                
                <Col>
                    {
                        (episode.publishDate != null && 
                            <p className="text-right m-1">
                                Published on {formatDate(episode.publishDate)}
                            </p>
                        ) ?? <p>Nope</p>
                    }
                </Col>
            </Row>
        </Col></Row>
        <Row>
            <Col sm={{span: 3, offset: 8}} className="d-flex justify-content-end align-items-center">
                <FacebookShareButton url={window.location.href}
                                        className="facebook-share">
                    <FacebookIcon 
                        size={32}
                        round/><span>Share</span>
                </FacebookShareButton>
                <TwitterShareButton
                    url={window.location.href}
                    title={episode.title}
                    hashtags={["ramphiandfriends"]}
                    className="m-1 twitter-share">
                    <TwitterIcon
                        size={32}
                        round /><span>Tweet</span>
                </TwitterShareButton>
            </Col>
        </Row>
        <Row className="m-0 p-0">
            <Col className="m-0 p-0">
                <ComicNavigationButtons previousUrl={episode?.previousUrl} nextUrl={episode?.nextUrl} />
            </Col>
        </Row>
    </>}
    </Container>);
};

export default ComicDetailsPage;