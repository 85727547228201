import { useRef } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import config from "../config";

const TitleOfPage = () => <Routes>
    <Route path="/" element={"Home"} />
    <Route path="/privacy" element={"Privacy Policy"} />
    <Route path="/crew" element={"Meet the crew"} />
</Routes>

const Header = () => {

    const navbarRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();

    return (<header>
        <Container fluid>
            <Row>
                <Col md={{span: 10, offset:1}} className="d-flex justify-content-around">
                    <Link to="/">
                        <picture>
                            <source srcSet="/media/headerComic.png" media="(min-width: 576px)" />
                            <img className="img-fluid" src="/media/headerComic-mobile.png" alt="Welcome to Orange Ramphastos portfolio" />
                        </picture>
                    </Link>
                </Col>
            </Row>
        </Container>
        <Navbar bg="primary" variant="dark" className="justify-content-center" expand="md" collapseOnSelect={true}>
            <div className="w-100 d-md-none d-flex justify-content-between">
                <Navbar.Text className="mr-2 text-white">
                    <h3 className="m-0 p-0"><TitleOfPage /></h3>
                </Navbar.Text>
                <Navbar.Toggle />
            </div>
            <Navbar.Collapse ref={navbarRef}
                                className="justify-content-center">
                <Nav justify className="w-90"
                    activeKey={location.pathname}>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/" eventKey="/">Index</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/crew" eventKey="/crew">Crew</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="https://orangeramphastos.com/about">About the author</Nav.Link>
                    </Nav.Item>
                    {
                        config.showLinksTab &&
                        <Nav.Item>
                            <Nav.Link as={Link} to="/links" eventKey="/links">Links</Nav.Link>
                        </Nav.Item>
                    }
                    <Nav.Item>
                        <Nav.Link as={Link} to="/privacy" eventKey="/privacy">Privacy</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </header>)
};

export default Header;