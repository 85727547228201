import { Col, Container, Row } from "react-bootstrap";

const links = [
    {
        url: "https://discord.gg/BstYqbEna8",
        image: "/media/links/discord.png"
    },
    {
        url: "https://www.webtoons.com/en/canvas/ramphi-and-friends/list?title_no=788212",
        image: "/media/links/webtoon.png"
    }
]

const LinkPage = () => {
    return (<Container>
        <Row style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
        }}>
            <Col style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "20rem",
                marginBottom: "1rem"
            }}>
                {
                    links.map(l => <a className="mt-3" key={l.url} href={l.url}><img className="img-fluid" alt={l.image} src={l.image} /></a>)
                }
            </Col>
        </Row>
    </Container>)
}

export default LinkPage;