import { Container } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Footer from './Components/Footer';
import Header from './Components/Header';
import CastDetailPage from './Pages/CastDetailPage';
import CastIndexPage from './Pages/CastIndexPage';
import ComicDetailsPage from './Pages/ComicDetailPage';
import ComicsIndexPage from './Pages/ComicsIndexPage';
import FeedPage from './Pages/FeedPage';
import PrivacyPage from './Pages/PrivacyPage';
import LinkPage from './Pages/LinkPage';

const FillVerticalGap = () => 
  <div className="flex-grow-1"></div>;

function App() {
  return (
    <Container fluid className="min-vh-100 p-0 m-0 d-flex flex-column">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<ComicsIndexPage />} />
          <Route path="/feed.xml" element={<FeedPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/links" element={<LinkPage />} />
          <Route path="/crew" element={<CastIndexPage />} />
          <Route path="/crew/:character" element={<CastDetailPage />} />
          <Route path="/:title" element={<ComicDetailsPage />} />
        </Routes>
        <FillVerticalGap />
        <Footer />
      </Router>
    </Container>
  );
}

export default App;
