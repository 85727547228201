import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import config from "../config";
import ApiService, { ComicContents, EpisodeSummary } from "../Services/ApiService";

const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        dateStyle: "medium",
        timeStyle: "short"
    };
    const formatter = new Intl.DateTimeFormat(navigator.language, options);
    return formatter.format(date);
}

const ComicsIndexPage = () => {

    const [comic, setComic] = useState<ComicContents|null>(null);

    const [highlightEpisode, setHighlightEpisode] = useState<EpisodeSummary|null>(null);
    const [previewEpisode, setPreviewEpisode] = useState<EpisodeSummary|null>(null);

    useEffect(() => {
        const getComic = async() => {
            const contents = await ApiService.getService().getComicContents();
            contents.episodes.sort((a,b) => a.episode - b.episode);
            setComic(contents);
        };

        getComic();
    },[]);

    useEffect(() => {
        if (comic === null) {
            return;
        }

        if (comic.episodes.length > 0) {
            const lastEpisode = comic.episodes[comic.episodes.length-1];
            
            const timeSincePublished = new Date().valueOf() - lastEpisode.publishTime.valueOf();
            const timeSincePublishedDays = timeSincePublished / (86400*1000);

            if (timeSincePublishedDays < config.highlightDurationDays) {
                setHighlightEpisode(lastEpisode);
            }
        }

        if (comic.preview !== null) {
            setPreviewEpisode(comic.preview);
        }
    }, [comic])

    document.title = "Ramphi and friends";
    
    return <Container>
        <Row>
            <Col>
                <h4 className="text-center mt-2 mb-2">
                    {comic?.description}
                </h4>
            </Col>
        </Row>
        {previewEpisode != null && <Row className="border border-primary rounded p-1 m-0 bg-secondary mb-3">
            <Col xs={12} sm={6} md={4} lg={3} className="p-0">
                    <img src={previewEpisode.coverUrl} 
                        alt={"Upcoming episode"}
                        className="img-fluid" />
            </Col>
            <Col className="d-flex justify-content-center flex-column">
                <h3 className="mb-3">Upcoming episode!</h3>
                <h4 className="mb-3">Episode {previewEpisode.episode}</h4>
                <h4>Release date: {formatDate(previewEpisode.publishTime)}</h4>
            </Col>
        </Row>}
        {highlightEpisode != null && <Row className="border border-primary rounded p-1 m-1 bg-secondary mb-3">
            <Col xs={12} sm={6} md={4} lg={3} className="p-0">
                <a href={highlightEpisode.url}>
                    <img src={highlightEpisode.coverUrl} 
                        alt={highlightEpisode.title}
                        className="img-fluid" />
                </a>
            </Col>
            <Col className="d-flex justify-content-center flex-column">
                <h3 className="mb-3">{highlightEpisode.title}</h3>
                <h4>Episode {highlightEpisode.episode}</h4>
                <h4>Just released!</h4>
            </Col>
        </Row>}
        <Row>
            {comic?.episodes.map(episode => <Col  xs={12} sm={6} md={4} lg={3} key={episode.episode}>
                <a href={episode.url}>
                    <img src={episode.coverUrl} 
                        alt={episode.title}
                        className="img-fluid mb-2" />
                </a>
            </Col>)}
        </Row>
    </Container>;
};

export default ComicsIndexPage;