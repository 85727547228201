import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import ApiService, { CastDetailModel } from "../Services/ApiService";

const CastDetailPage = () => {
    
    const {character: characterParam} = useParams<{character: string}>();
    const characterId = characterParam ?? "";

    const [character, setCharacter] = useState<CastDetailModel | null>(null);

    useEffect(() => {
        const getCharacter = async() => {
            const charResponse = await ApiService.getService().getCastMember(characterId);
            setCharacter(charResponse);
        };

        getCharacter();
    },[characterId]);

    if (character == null) {
        return (
            <Container>
                <Row>
                    <Col className="d-flex justify-content-center mt-5">
                        <Spinner animation="border" role="status" variant="primary" />
                    </Col>
                </Row>
            </Container>
        );
    }
    
    return (
        <Container>
            <Row>
                <Col xs={{span: 10, offset: 1}}>
                    <Row>
                        <Col md={{offset:5}}>
                            <h1 className="text-center mt-4 mb-3">{character.name}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <img className="w-100" src={character.iconUrl} alt={`${character.name} profile`} />
                            <Row className="mt-2 mb-3">
                                {character.portfolio.map(p => <Col md={6} key={p}>
                                    <img className="w-100" src={p} alt={p} />
                                </Col>)}
                            </Row>
                        </Col>
                        <Col>
                            <ReactMarkdown children={character.description} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default CastDetailPage;