import ApiService from "./ApiService";

export interface ILikeService {
    addLike(title: string) : Promise<void>;
    removeLike(title: string) : Promise<void>;
    getLikeCount(title: string) : Promise<number>;
    getUserLike(title: string) : Promise<boolean>;
}

export default class LikeService implements ILikeService {

    static STORAGE_KEY : string = "liked-comics";

    addLike(title: string): Promise<void> {
        const apiPromise = ApiService.getService().addLike(title);

        const currentValue = localStorage.getItem(LikeService.STORAGE_KEY) ?? "[]";

        const currentLikes = JSON.parse(currentValue) as string[];

        if (!currentLikes.includes(title)) {
            currentLikes.push(title);
        }

        const newValue = JSON.stringify(currentLikes);

        localStorage.setItem(LikeService.STORAGE_KEY, newValue);

        return apiPromise;
    }
    removeLike(title: string): Promise<void> {
        const apiPromise = ApiService.getService().removeLike(title);

        const currentValue = localStorage.getItem(LikeService.STORAGE_KEY) ?? "[]";

        const currentLikes = JSON.parse(currentValue) as string[];

        if (currentLikes.includes(title)) {
            const index = currentLikes.indexOf(title);
            if (index > -1) {
                currentLikes.splice(index, 1);
            }
        }

        const newValue = JSON.stringify(currentLikes);

        localStorage.setItem(LikeService.STORAGE_KEY, newValue);

        return apiPromise;
    }
    getLikeCount(title: string): Promise<number> {
        return ApiService.getService().getLikes(title);
    }
    getUserLike(title: string): Promise<boolean> {
        const currentValue = localStorage.getItem(LikeService.STORAGE_KEY) ?? "[]";

        const currentLikes = JSON.parse(currentValue) as string[];

        return Promise.resolve(currentLikes.includes(title));
    }
    static instance : ILikeService | null= null;
    static getService() : ILikeService {
        if (this.instance === null) {
            this.instance = new LikeService();
        }
        return this.instance;
    }
}