import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Navbar } from "react-bootstrap";
import SocialsService from "../Services/SocialsService";

const Footer = () => {

    const socials = new SocialsService().getSocials();

    return(<footer>
        <Navbar bg="primary" variant="dark" className="justify-content-around text-center">
            <Nav>
                {
                    socials.map(s => <Nav.Link key={s.service} href={s.href} title={s.service}>
                        <FontAwesomeIcon className="mr-1" icon={s.icon} />
                    </Nav.Link>)
                }
            </Nav>
        </Navbar>
    </footer>)
};

export default Footer;